import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/home';

function App() {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-grow">
        <Home />
      </div>
      <div className="flex-initial">
        <Footer />
      </div>
    </div>
    
  );
}

export default App;
