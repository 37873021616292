// import React from 'react';
// import ReactDOM from 'react-dom';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

export default function Header() {
  return (
    <div className="h-20 bg-white w-full fixed flex">
      <div className="flex-1 my-auto mx-4">
        <p className="text-5xl font-inspiration mx-4">Simplistic</p>
      </div>  
      <div className="flex-1 flex-row flex justify-end mx-4">
      </div>
    </div>
  );
}