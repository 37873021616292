import image from './image.png';

export default function Home() {
  return (  
    <div className="w-full">
      <section className="bg-white justify-center flex">
        <div className="w-8/12">
          <div className="py-16">
            <p className="text-9xl text-center mt-2 font-poppins hidden md:block">Simplistic</p>
            <p className="text-6xl text-center mt-6 md:mt-16 font-poppins">Hvem er vi?</p>
            <div className="flex flex-row mt-6 whitespace-nowrap">
              <div className="flex-1" />
              <div className="flex-1 mr-4">
                <p className="text-center text-xl font-medium">Styrets leder</p>
                <p className="text-center text-xl mt-px">Peter Skoland</p>
                <div className="flex justify-center">
                  <a className="text-center text-xl underline hover:text-gray-500" href="tel:93669256">936 69 256</a>
                </div>
              </div>
              <div className="flex-1 ml-4">
                <p className="text-center text-xl font-medium">Daglig leder</p>
                <p className="text-center text-xl mt-px">David Skoland</p>
                <div className="flex justify-center">
                  <a className="text-center text-xl underline hover:text-gray-500" href="tel:98830877">988 30 877</a>
                </div>
              </div>
              <div className="flex-1 invisible" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}